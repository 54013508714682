.beta-request-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-section {
  text-align: center;
  margin-bottom: 20px;
}

.app-logo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
  display: block;
  margin: 0 auto;
  padding-bottom: 20px;
}

.welcome-section h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.welcome-section p {
  font-size: 16px;
  color: #555;
}

.beta-request-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.thank-you-message {
  text-align: center;
  margin-top: 10px;
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
}

.thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 20px;
}

.thank-you-card {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 600px;
}

.marketing-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.marketing-button:hover {
  background-color: #0056b3;
}