.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.password-reset-form {
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 0 auto; /* Center the form horizontally */
}

.welcome-section {
  text-align: center;
  margin-bottom: 20px;
}

.app-logo {
  display: block;
  margin: 0 auto;
  padding-bottom: 20px;
}

.thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.thank-you-card {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.thank-you-message {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
}

.marketing-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.marketing-button:hover {
  background-color: #0056b3;
}

.password-reset-form .form-group,
.beta-request-form .form-group {
  margin-bottom: 15px;
}

.password-reset-form .form-group label,
.beta-request-form .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.password-reset-form .form-group input,
.beta-request-form .form-group input,
.beta-request-form .form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 20px;
  text-align: center;
}